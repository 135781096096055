<template>
    <b-navbar-nav class="nav align-items-center ml-auto dropdown-language-custom">
        <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link  " style="padding-left: 0px !important;">
            <template #button-content>
                <div class="d-flex user-nav test">
                    <b-img height="14px" width="22px" :src="localeObjects.find((x)=>x.id === locale.id).img"/>
                </div>
            </template>
            <b-dropdown-item v-for="(item) in localeObjects" @click="setLocale(item)" :key="item.id" link-class="d-flex align-items-center" >
                <b-img height="14px" width="22px" :src="item.img"/>
                <span class="ml-1 ">{{item.name}}</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar-nav>
</template>
<script>
    import {BDropdownItem, BImg, BNavbarNav, BNavItemDropdown} from 'bootstrap-vue'
    import i18n from '@/i18n/i18n'
    import {Locales, getLocaleObjects} from '@/libs/enums/Locales'
    export default {
        components:{
            BDropdownItem, BImg, BNavItemDropdown, BNavbarNav
        },
        data() {
            return {
                locale:{
                    id: Locales.Slovenian,
                    label: i18n.t('menu.locales.slovenian')
                }
            }
        },
        computed:{
            localeObjects() {
                return getLocaleObjects()
            },
            current_locale() {
                return this.$store.getters['user/current_locale']
            },
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            userData() {
                return this.$store.getters['user/userData']
            }
        },
        methods:{
            async setLocale(locale) {
                this.locale = locale
                if (this.loggedIn) {
                    await this.$http.post(`/api/client/v1/users/${this.userData.id}/${locale.id}`)
                }
                await this.$store.dispatch('user/setLanguage', locale.id)
            }
        },
        mounted() {
            this.locale = this.localeObjects.find((x) => x.id === this.current_locale)
        }
    }
</script>

<style>
  .dropdown-language-custom .dropdown-toggle::after{
    display: none
  }
</style>
